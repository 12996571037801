<template>
    <zw-sidebar @shown="shown" :title="$t('settings.warehouse.title')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.shelf_name"
                                        name="shelf_name"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.capacity"
                                        name="capacity"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        type="number"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <b-form-group :label="$t(labelPrefix + 'active')">
                            <b-form-checkbox
                                v-model="form.active"
                                switch
                                name="active"
                                value="1"
                                unchecked-value="0"
                            >
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'ShelveModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                shelf_name: '',
                capacity: 1,
                active: true,
            },
            form: {},
            labelPrefix: 'stock.label.',
        }
    },
    methods: {
        ...mapGetters('Stock', ['getShelve']),
        shown() {
            const shelve = this.$store.dispatch('Stock/fetchShelve', this.payload.id)

            Promise.all([shelve])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getShelve()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    this.$store.dispatch('Stock/saveShelve', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            console.log(errors)
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>